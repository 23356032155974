// src/store/index.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dateReducer from "./dateSlice";
import userReducer from "./usersSlice";
import confirmationReducer from "./confirmationSlice";
import descriptionSlice from "./descriptionSlice";
import locationsReducer from "./locationSlice";
import deviceReducer from "./deviceSlice";
import currencyReducer from "./currencySlice";
import officeReducer from "./officeSlice.ts";

const store = configureStore({
  reducer: {
    auth: authReducer,
    dates: dateReducer,
    users: userReducer,
    confirmation: confirmationReducer,
    currencies: currencyReducer,
    description: descriptionSlice,
    locations: locationsReducer,
    device: deviceReducer,
    office: officeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
