import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OfficeState {
  currentOffice: string | null;
  accessibleOffices: string[] | null;
}

// 從 localStorage 初始化
const getInitialState = (): OfficeState => {
  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
  const userOffice = storedUser?.user?.office || null;
  const userAccessibleOffices =
    storedUser?.user?.accessibleOffices || (userOffice ? [userOffice] : null);

  // 檢查 localStorage 中是否有當前選擇的辦公室
  const storedCurrentOffice = localStorage.getItem("currentOffice");

  // 如果有存儲的辦公室，並且它在可訪問的辦公室列表中，則使用它
  // 否則使用用戶的主辦公室
  const currentOffice =
    storedCurrentOffice && userAccessibleOffices?.includes(storedCurrentOffice)
      ? storedCurrentOffice
      : userOffice;

  return {
    currentOffice,
    accessibleOffices: userAccessibleOffices,
  };
};

export const officeSlice = createSlice({
  name: "office",
  initialState: getInitialState(),
  reducers: {
    setCurrentOffice: (state, action: PayloadAction<string>) => {
      state.currentOffice = action.payload;
      // 同時保存到 localStorage
      localStorage.setItem("currentOffice", action.payload);
    },
    setAccessibleOffices: (state, action: PayloadAction<string[]>) => {
      state.accessibleOffices = action.payload;
    },
    // 用於處理用戶登入或登出時的狀態重置
    resetOffice: (state) => {
      const newState = getInitialState();
      state.currentOffice = newState.currentOffice;
      state.accessibleOffices = newState.accessibleOffices;
    },
  },
});

export const { setCurrentOffice, setAccessibleOffices, resetOffice } =
  officeSlice.actions;

export default officeSlice.reducer;
