import React, { useState, useEffect } from "react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Building2, ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";

const OFFICES = {
  TWKHH: "Taiwan",
  CNSHA: "China",
  SGSIN: "Singapore",
};

interface OfficeSelectorProps {
  currentOffice: string;
  accessibleOffices: string[];
  onOfficeChange: (office: string) => void;
}

const OfficeSelector: React.FC<OfficeSelectorProps> = ({
  currentOffice,
  accessibleOffices,
  onOfficeChange,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOffice, setSelectedOffice] = useState<string>(currentOffice);

  useEffect(() => {
    setSelectedOffice(currentOffice);
  }, [currentOffice]);

  // 只有當用戶有多個可訪問辦公室時才顯示選擇器
  if (!accessibleOffices || accessibleOffices.length <= 1) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOfficeSelect = (office: string) => {
    setSelectedOffice(office);
    onOfficeChange(office);
    handleClose();
  };

  const getOfficeName = (officeCode: string) => {
    return OFFICES[officeCode as keyof typeof OFFICES] || officeCode;
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        endIcon={<ChevronDown size={16} />}
        startIcon={<Building2 size={18} />}
        sx={{
          textTransform: "none",
          fontWeight: "medium",
          color: "text.secondary",
          borderRadius: "4px",
          padding: "6px 10px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <Typography variant="body2" noWrap>
          {getOfficeName(selectedOffice)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            minWidth: "200px",
          },
        }}
      >
        {accessibleOffices.map((office) => (
          <MenuItem
            key={office}
            onClick={() => handleOfficeSelect(office)}
            selected={office === selectedOffice}
          >
            <ListItemIcon>
              <Building2 size={18} />
            </ListItemIcon>
            <ListItemText primary={getOfficeName(office)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OfficeSelector;
