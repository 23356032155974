import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
import { resetOffice } from "./officeSlice.ts";

const initialState = {
  currentUser: AuthService.getCurrentUser(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.currentUser = null;
    },
  },
});

export const { setCurrentUser, logout } = authSlice.actions;

// 創建一個更新當前用戶並同時重置辦公室狀態的 thunk
export const updateCurrentUserAndResetOffice = (user) => (dispatch) => {
  dispatch(setCurrentUser(user));
  dispatch(resetOffice());
};

// 創建一個登出並清除辦公室狀態的 thunk
export const logoutAndClearOffice = () => (dispatch) => {
  dispatch(logout());
  dispatch(resetOffice());
};

export default authSlice.reducer;
